export default () => ({
	async init() {
		const { default: gsap } = await import("../libs/gsap")
		const steps = this.$root.querySelectorAll(".step-row")

		steps.forEach((step) => {
			gsap.to(step, {
				scrollTrigger: {
					trigger: step,
					start: "top center",
					end: "center center",
					scrub: true,
				},
				opacity: 1,
			})
		})

		gsap.to(this.$refs.stepsOk, {
			scrollTrigger: {
				trigger: this.$refs.stepsOk,
				start: "top center",
				end: "center center",
				scrub: true,
			},
			opacity: 1,
		})

		gsap.to(this.$refs.horizontalLine, {
			scrollTrigger: {
				trigger: this.$root,
				scrub: true,
				start: "top-=250px center",
				end: "top+=50px center",
			},
			scaleX: 1,
			ease: "none",
		})

		gsap.to(this.$refs.verticalLine, {
			scrollTrigger: {
				trigger: this.$root,
				scrub: true,
				start: "top+=50px center",
				end: "bottom center",
			},
			scaleY: 1,
			ease: "none",
		})
	},
})
