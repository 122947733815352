export default () => ({
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    new Swiper(this.$root, {
      speed: 500,
      spaceBetween: 11,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  },
});
