// import noUiSlider from "nouislider"
import { formatNumber } from "../utils/formatNumber"

export default ({ unit, start, step = 1, min = 0, max = 100 }) => ({
	value: start,
	async init() {
		import("nouislider/dist/nouislider.min.css")
		const { default: noUiSlider } = await import("nouislider")

		const slider = noUiSlider.create(this.$refs.range, {
			start,
			step,
			connect: [true, false],
			tooltips: this.formatter,
			pips: {
				mode: "values",
				values: [min, max],
				density: 100,
				format: this.formatter,
			},
			range: {
				min,
				max,
			},
		})

		slider.on("set", (value, handleNumber, unencoded) => {
			this.value = slider.get(true)
		})
	},
	formatter: {
		to: (value) => {
			return `${formatNumber(value)} ${unit}`
		},
	},
})
