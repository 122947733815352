import Alpine from "alpinejs";

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import blogSlider from "./blogSlider";
Alpine.data("blogSlider", blogSlider);

import phoneInputMask from "./phoneInputMask";
Alpine.data("phoneInputMask", phoneInputMask);

import contactsMap from "./contactsMap";
Alpine.data("contactsMap", contactsMap);

import fancyboxGallery from "./fancyboxGallery";
Alpine.data("fancyboxGallery", fancyboxGallery);

import contentSlider from "./contentSlider";
Alpine.data("contentSlider", contentSlider);

import workSteps from "./workSteps";
Alpine.data("workSteps", workSteps);

import playVideo from "./playVideo";
Alpine.data("playVideo", playVideo);

import rangeInput from "./rangeInput";
Alpine.data("rangeInput", rangeInput);

Alpine.store("mainMenu", {
  isOpen: false,
  menu: document.querySelector("#main-menu"),
  toggle() {
    this.isOpen = !this.isOpen;
  },
});

Alpine.store("header", {
  class: window.initialHeaderClass,
  initialClass: window.initialHeaderClass,
});

Alpine.store("searchForm", {
  isOpen: false,
});

Alpine.start();
