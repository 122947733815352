import Map from "../libs/Map"

export default (coordinates = "") => ({
	async init() {
		await Map.loadAPI()
		const coords = coordinates.split(",", 2).map((value) => parseFloat(value))

		const map = new Map({
			container: this.$root,
			center: coords,
			zoom: 12,
		})
		map.addMarker(coords, Map.createDefaultMarker())
	},
})
