export default () => ({
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");

    const thumbsSwiper = new Swiper(this.$refs.thumbsSwiper, {
      speed: 500,
      spaceBetween: 20,
      slidesPerView: 2,
      breakpoints: {
        992: {
          slidesPerView: 4,
        },
      },
    });
    const mainSwiper = new Swiper(this.$refs.mainSwiper, {
      speed: 800,
      spaceBetween: 30,
      thumbs: { swiper: thumbsSwiper },
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
    });
  },
});
